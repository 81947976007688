import React from 'react'
import classNames from 'classnames'
import styles from './container.module.scss'

interface Container {
  full?: boolean
  className?: string
  children?: React.ReactNode
}

const Container: React.FC<Container> = ({ children, full, className }: Container) => {
  const containerClasses = classNames(
    styles.container,
    {
      [styles.full]: full,
    },
    className
  )
  return <div className={containerClasses}>{children}</div>
}

export default Container
