import React, { ReactNode } from 'react'
import styles from './filter-option.module.scss'

interface FilterOption {
  children: ReactNode
  title?: string
}

const FilterOption: React.FC<FilterOption> = ({ title, children }: FilterOption) => (
  <div className={styles.filterOption}>
    {title && <h3 className={styles.filterOptionTitle}>{title}</h3>}
    {children}
  </div>
)

export default FilterOption
