import React, { useContext, useEffect, useRef, useState } from 'react'
import Container from 'components/container'
import Header from 'components/header'
import Footer from 'components/footer'
import styles from './faq.module.scss'
import IframeResizer from 'iframe-resizer-react'
import { AppContext } from 'state'

import {
  Footer as FooterProp,
  Header as HeaderProp
} from 'state/types'


const FaqsView: React.FC = () => {

  const { platesJson } = useContext(AppContext)
  const mainUrl = `${process.env.REACT_APP_DOT_SERVER_URL}`+platesJson.FAQs?.url?.filename_url

  const [footer, setFooter] = useState<FooterProp | null>(
    (platesJson.footer) || null
  )
  const [header, setHeader] = useState<HeaderProp | null>(
    (platesJson.header) || null
  )

  useEffect(() => {
    if (platesJson.footer) {
      console.log('main footer---',platesJson.footer.PrivacyPolicy_link)
      setFooter(platesJson.footer)
    }
    if (platesJson.header) {
      console.log('main header---',platesJson.header.FAQs_link)
      setHeader(platesJson.header)
    }
  }, [platesJson])
  return (
    <>
      <div style={{minHeight:window.innerHeight}}>
        <Container full className={styles.headerBg}>
          {/* <Container> */}
          {header && <Header {...header} />}
          {/* </Container> */}
        </Container>
          {/* <Iframe url={mainUrl}
              id="myIframe"
              display="block"
              position="relative"
              width="100%" scrolling="no" height= "1300px"
              /> */}
          <IframeResizer
            //forwardRef={iframeRef}
            heightCalculationMethod="lowestElement"
            inPageLinks
            log
            //onMessage={onMessage}
            //onResized={onResized}
            src={mainUrl}
            className={styles.mainFAQIframe}
            style={{ width: '1px', minWidth: '100%'}}
          />
        <Container full className={styles.footerBg}>
          {footer && <Footer {...footer} />}
        </Container>
      </div>
    </>
  )

}


export default FaqsView
