import React from 'react'
import Link from 'components/link'
import styles from './filter-header.module.scss'
import helperStyles from '../../styles/_helpers.module.scss'

interface RulesLink {
  text?: string
  url?: string
  styleHeading?: { [key: string]: string }[]
}

interface FilterHeader {
  seriesName?: string;
  rulesLink?: RulesLink
  onPlateSearch?: (plateText: string) => void
}

const FilterHeader: React.FC<FilterHeader> = ({ rulesLink, onPlateSearch, seriesName }: FilterHeader) => {
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    if (onPlateSearch) {
      const { value: plateText } = event.currentTarget
      // Always transform plate text to uppercase for the regexp.
      onPlateSearch(plateText.toUpperCase())
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => event.preventDefault()

  return (
    <header className={styles.filterHeader}>
      <div className={styles.intro}>
        <h2 className={styles.title}>{seriesName ? seriesName : 'Choose your style'}</h2>
        {rulesLink && seriesName !== 'Platinum' && seriesName !== 'Euro style' && (
          <p className={styles.copy}>
            {rulesLink.text}
            {/* {' '}See
            {rulesLink.url && (
              <Link
                narrow
                nonBreaking
                href={rulesLink.url}
                target='_blank'
                rel='noopener noreferrer'
              >
                Plate design features.
              </Link>
            )} */}
          </p>
        )}
      </div>
      {/* <form className={styles.plateTextForm} onSubmit={handleSubmit} noValidate autoComplete='off'>
        <label>
          <span className={helperStyles.visuallyHidden}>Enter characters for your plate</span>
          <input
            onChange={handleChange}
            type='search'
            name='plateText'
            className={styles.plateTextInput}
            placeholder='123 ABC 456'
            autoComplete='off'
            maxLength={11}
          />
        </label>
      </form> */}
    </header>
  )
}

export default FilterHeader
