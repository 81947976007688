import React from 'react'
import Plate from 'components/plate'
import PlateCount from 'components/platecount'
import styles from './plate-grid.module.scss'
import classNames from 'classnames'
import { Plate as PlateData } from 'utils'

interface PlateGrid {
  plates: PlateData[] | null
  displayCount?: boolean
  className?: string
  mainSeries:string
}

const PlateGrid: React.FC<PlateGrid> = ({ plates, displayCount, className, mainSeries }: PlateGrid) => {
  const plateGridClasses = classNames(
    styles.plateGrid,
    {
      [styles.noResults]: !(plates && plates.length),
    },
    className
  )
  let condition = false;
  var classNewGrid ="";
  if(mainSeries === 'platinum'){
    classNewGrid = 'classNewGrid';
  }
  var classNewGridULClass ="";
  if(mainSeries === '/choose-your-style'){
    condition = true;
    classNewGridULClass = classNames({
      [styles.classNewGridUL]: (condition),
    })
  }
  console.log('chooseee----mainSeries---',plates)
  return (
    <div className={plateGridClasses}>
      {displayCount && plates && (
        <p className={styles.count}>
          <PlateCount count={plates.length} />
        </p>
      )}
      {plates && plates.length > 0 && (
        <ul className={styles.list+' '+classNewGridULClass}>
          {plates.map((plate, index) => (
            <li key={index} className={styles.listItem+ ' ' +styles.listItem1+ ' ' +styles.classNewGrid}>
              <Plate plate={plate} className={styles.plate} mainSeries={mainSeries} />
            </li>
          ))}
        </ul>
      )}
      {plates && plates.length === 0 && (
        <div className={styles.noResultsCopy}>
          <p>Oops, no plates match that combination.</p>
          <p>Try adjusting the filters or plate text.</p>
        </div>
      )}
      {!plates && (
        <div className={styles.loading}>
          <p>Loading plates&hellip;</p>
        </div>
      )}
    </div>
  )
}

export default PlateGrid
