import React from 'react'
import styles from './plate-count.module.scss'
import helperStyles from 'styles/_helpers.module.scss'

interface PlateCount {
  count: number
}

const PlateCount: React.FC<PlateCount> = ({ count }: PlateCount) => (
  <span className={styles.plateCount} aria-live='polite'>
    {count} {count !== 1 ? 'plates' : 'plate'}
    <span className={helperStyles.visuallyHidden}>found.</span>
  </span>
)

export default PlateCount
