import React, { useState } from 'react'
import Logo from 'components/logo'
import styles from './footer.module.scss'
import { Link } from 'react-router-dom'
import { routes } from 'App'
import classNames from 'classnames'

interface PrivacyPolicyLink {
  text?: string
  url?: string
}
interface TermsOfUseLink {
  text?: string
  url?: string
}
interface CollectionLink {
  text?: string
  url?: string
}
interface BizPlatesTermsLink {
  text?: string
  url?: string
}
interface BizPlatesGuideLines {
  text?: string
  url?: string
}
interface ContactusLink {
  text?: string
  url?: string
}

export interface FooterMain {
  PrivacyPolicy_link?: PrivacyPolicyLink
  TermsOfUse_link?: TermsOfUseLink
  Collection_link?: CollectionLink
  BizPlatesTerms_link?: BizPlatesTermsLink
  BizPlatesGuidelines?: BizPlatesGuideLines
  ContactUs?: ContactusLink
}
const Footer: React.FC<FooterMain> = ({ PrivacyPolicy_link, TermsOfUse_link, Collection_link, BizPlatesTerms_link, BizPlatesGuidelines, ContactUs }: FooterMain) => {
//   console.log('height0----', window.innerHeight)
 console.log('privacy url---',ContactUs?.text)
const mainFooter = 'footer';

var condition = true;
var footerIsActiveClass ="";
footerIsActiveClass = classNames({
  [styles.active]: (condition),
})


  return (
    <div className={styles.footer} role='banner'>
      <div className={styles.footer_logo_container}>
      <ul>
          <li>
            <a href={PrivacyPolicy_link?.url} rel="noopener noreferrer" target="_blank">
              {PrivacyPolicy_link?.text}
            </a>
          </li>
          <li>
            <Link to={routes.terms.path} target='_blank' rel="noopener noreferrer">
              {TermsOfUse_link?.text}
            </Link>
          </li>
          <li>
            <a href={Collection_link?.url} target='_blank' rel="noopener noreferrer">
              {Collection_link?.text}
            </a>
          </li>
          <li>
            <Link to={routes.bizTerms.path} target='_blank' rel="noopener noreferrer">
              {BizPlatesTerms_link?.text}
            </Link>
          </li>
          <li>
            <Link to={routes.bizGuide.path} target='_blank' rel="noopener noreferrer">
              {BizPlatesGuidelines?.text}
            </Link>
          </li>
          <li>
            <Link to={routes.contactUs.path} target='_blank' rel="noopener noreferrer" className={footerIsActiveClass}>
              {ContactUs?.text}
            </Link>
          </li>
      </ul>
      <div className={styles.footerLogoContGov}>
        <Logo mainData={mainFooter}/>
          {/* <img src="https://plateswa.com/images/plates-logo.svg" alt="LOGO" width="110" className={styles.imgFluid} /> */}
          <a href="https://www.transport.wa.gov.au/" target='_blank'><img src={`${process.env.REACT_APP_DOT_SERVER_URL}/PlatesWA/DoTLogo2021_188x39px_white.svg`} alt="LOGO" width="170" className={styles.imgFluid} /></a>
      </div>
      </div>
    </div>
  )
}

export default Footer
