import React, { useState, useEffect } from 'react'
import * as Color from 'color'
import {
  IPlatesJson,
  Series,
  Sizes,
  DefaultSize,
  CombinationSize,
  PlateDetail,
  StoreFront,
  SeriesColors,
} from 'state/types'

export const renderObject = (obj: {}) => <pre>{`${JSON.stringify(obj, null, 2)}`}</pre>

export const toMoney = (value: number, { decimals }: { decimals: 0 | 2 } = { decimals: 0 }) => {
  if (isNaN(value)) return null

  let money: string | number = value

  if (decimals === 0) {
    money = Math.trunc(money)
  }

  if (decimals === 2) {
    money = money.toFixed(2)
  }

  return <>${money}</>
}

export const isValidColor = (color: string) => {
  try {
    Color.default(color)
    return true
  } catch {
    return false
  }
}

export function useMedia(mediaQuery: string) {
  const query = window.matchMedia(mediaQuery)
  const [match, setMatch] = useState<boolean>(query.matches)

  useEffect(() => {
    // Update state on window resize
    const handleResize = () => setMatch(query.matches)
    query.addListener(handleResize)
    // Remove event listener on cleanup
    return () => query.removeListener(handleResize)
  }, [query]) // Empty array ensures effect is only run on mount and unmount

  return match
}

export interface IFilter {
  material: Series['material'][]
  colors: Plate['colors']
  vehicle: keyof Sizes
  series: Series['series']
  patterns: string
  sizes: string[]
  priceRange: number[]
}

export const generateFilters = (
  series: IPlatesJson['series'],
  key: keyof Plate,
  key2?: string | false,
  returnKey = false
) => {
  if (!series) {
    return []
  }
  const filters: IFilter['colors'] | IFilter['vehicle'] | IFilter['material'] = []
  const parseData = (value: any) => {
    const type = Array.isArray(value) ? 'array' : typeof value
    switch (type) {
      case 'string':
        filters.push(value)
        break
      case 'object':
        if (key2) {
          value[key2] && parseData(value[key2])
          !value[key2] && Object.keys(value).forEach(k => parseData(value[k]))
        } else {
          returnKey && Object.keys(value).forEach((k: any) => filters.push(k))
          !returnKey && Object.values(value).forEach((v: any) => filters.push(v))
        }
        break
      case 'array':
        value.forEach((val: any) => {
          parseData(val)
        })
        break
      default:
        process.env.REACT_APP_ENV === 'development' && console.error(`Value not found in data`)
    }
  }
  series.forEach((s: any) => {
    parseData(s[key])
  })
  return Array.from(new Set(filters))
}

const hasSlimline = (sizes: Sizes): boolean => {
  // Only cars have a 'slimline' size
  if (!sizes || !sizes.car || !sizes.car.slimline) return false
  const { title, size } = sizes.car.slimline
  // 'null' string indicates that there is nothing set.
  const hasTitle = !!title && !!title.length && title !== 'null'
  const hasSize = !!size && !!size.length && size !== 'null'
  return hasTitle && hasSize
}

export interface PlateColor {
  name: string
  value: string
}

export interface Plate {
  name: string
  series: string
  images: { low: string; high: string }
  start_price: number
  trelis_type: string
  colors: string[]
  series_colors: PlateColor[]
  material: string
  patterns: string[]
  vehicle: string[]
  popular: boolean
  store_front: StoreFront
  slimline: boolean
  sizes: string[]
}

export const generatePlates = (data: IPlatesJson['series'] | null): Plate[] | null => {
  if (!data) {
    return null
  }
  const plates: Plate[] = []
  data.forEach(series => {
    Object.values(series.colors).forEach((color: PlateDetail) => {
      plates.push({
        name: series.name,
        series: series.series,
        images: { low: color.image_low, high: color.image_high },
        start_price: series.start_price,
        trelis_type: series.trelis_type,
        colors: processColorString(color.value),
        series_colors: sortColorsByContrast(generateColors(series.colors)),
        material: series.material,
        patterns: series.patterns,
        vehicle: generateVehicles([series]),
        popular: color.popular,
        store_front: series.store_front,
        slimline: hasSlimline(series.sizes),
        sizes: generateSizes([series]),
      })
    })
  })
  return plates
}

export const generateColors = (colors: SeriesColors | Plate['colors']): Plate['series_colors'] => {
  let generatedColors: any[] = []
  const getColors = (color: PlateDetail) => {
    const values = processColorString(color.value).map((value: PlateDetail['value']) => {
      return { name: color.name, value }
    })
    generatedColors = generatedColors.concat(values)
  }
  // if we are passing in a color array made by generateFilters
  if (Array.isArray(colors)) {
    colors.forEach((color: any) => getColors(color))
    // else we are passing in the raw plates json
  } else {
    Object.entries(colors).forEach((color: [string, PlateDetail]) => getColors(color[1]))
  }
  // return the generated colors whilst stripping duplicates
  return Array.from(new Set(generatedColors.map(c => c.value))).map(value =>
    generatedColors.find(c => c.value === value)
  )
}

export const generateVehicles = (series: IPlatesJson['series']): Array<keyof Sizes> => {
  const vehicles: Array<keyof Sizes> = []
  if (!series) {
    return vehicles
  }
  series.forEach((s: Series) => {
    if (s.sizes) {
      ;(Object.keys(s.sizes) as Array<keyof Sizes>).forEach(key => {
        Object.values(s.sizes[key as keyof Sizes]).forEach((val: DefaultSize & CombinationSize) => {
          if (isValidSize(val)) {
            vehicles.push(key)
          }
        })
      })
    }
  })
  return Array.from(new Set(vehicles))
}

export const generateSizes = (
  series: IPlatesJson['series'],
  type?: keyof Sizes,
  activeSeries?: string
) => {
  // if (series) {
  //   series = activeSeries ? series.filter(s => s.series === activeSeries) : series;
  // }
  if (!type) {
    const sizes: any = []
    if (!series) {
      return sizes
    }
    series.forEach((s: Series) => {
      if (s.sizes) {
        ;(Object.keys(s.sizes) as Array<keyof Sizes>).forEach(key => {
          Object.values(s.sizes[key as keyof Sizes]).forEach(
            (val: DefaultSize & CombinationSize) => {
              if (isValidSize(val)) {
                sizes.push(val.title)
              }
            }
          )
        })
      }
    })
    return Array.from(new Set(sizes))
  } else {
    const sizes: any = {}
    if (!series) {
      return sizes
    }
    series.forEach((s: Series) => {
      if (s.sizes && s.sizes[type]) {
        Object.keys(s.sizes[type]).forEach(key => {
          const size: DefaultSize & CombinationSize = (s.sizes[type] as any)[key]
          if (isValidSize(size)) {
            sizes[safeString(size.title)] = size
          }
        })
      }
    })
    return sizes
  }
}

export const isValidSize = (size: DefaultSize & CombinationSize) => {
  if (
    size.combo1 &&
    size.combo2 &&
    isValidJsonValue(size.combo1) &&
    isValidJsonValue(size.combo2) &&
    isValidJsonValue(size.title)
  ) {
    return true
  } else if (isValidJsonValue(size.title) && isValidJsonValue(size.size)) {
    return true
  }
  return false
}

export const isValidJsonValue = (value: string) => {
  if (value && value.toLowerCase() !== 'null' && value.toLowerCase() !== 'string') {
    return true
  } else {
    return false
  }
}

export const safeString = (string: string) => {
  return string.toLowerCase().replace(/[^a-z]/g, '_')
}

export interface PriceRangeMinMaxValues {
  min: number
  max: number
  defaultValues: number[]
}

export const generatePriceRangeMinMaxValues = (
  series: IPlatesJson['series']
): PriceRangeMinMaxValues | null => {
  const values: PriceRangeMinMaxValues = {
    min: Infinity,
    max: 0,
    defaultValues: [],
  }

  if (!(series && series.length)) {
    return null
  }

  // get the minimum start price
  values.min = series.reduce(
    (min, { start_price }) => (start_price < min ? start_price : min),
    values.min
  )
  // get the maximum start price
  values.max = series.reduce(
    (max, { start_price }) => (start_price > max ? start_price : max),
    values.max
  )

  // update the default values & round
  values.defaultValues = [Math.floor(values.min / 10) * 10, Math.ceil(values.max / 10) * 10]

  values.min = 0
  // round the maximum value to the nearest 100 + 100 for a bigger buffer
  values.max = Math.ceil(values.max / 100) * 100 + 100

  return values
}

const processColorString = (string: string) => {
  // split the colors into an array
  let values = string.split(',')
  // get rid of unwanted space
  values = values.map((val: string) => val.trim())
  // filter out invalid colors
  values = values.filter((val: string) => isValidColor(val))
  // make unique
  return Array.from(new Set(values))
}

export const sortPlatesByName = (plateData: Plate[]) =>
  plateData.sort((a, b) => {
    const seriesA = a.series.toUpperCase() // equalise the data
    const seriesB = b.series.toUpperCase() // equalise the data
    if (seriesA < seriesB) return -1
    if (seriesA > seriesB) return 1
    return 0
  })

export const sortColorsByContrast = (colors: PlateColor[]): PlateColor[] => {
  const sortedColors = colors.sort((a, b) => {
    const colorA = Color.default(a.value).contrast(Color.default('#000'))
    const colorB = Color.default(b.value).contrast(Color.default('#000'))
    if (colorA < colorB) return -1
    if (colorA > colorB) return 1
    return 0
  })
  return sortedColors
}
