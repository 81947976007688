import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import styles from './checkbox.module.scss'
import { ReactComponent as IconTick } from 'assets/images/icon-tick.svg'

interface Checkbox {
  label: string
  value: string
  look?: 'radio'
  size?: string
  combo1?: string
  combo2?: string
  checked?: boolean
  disabled?: boolean
  capitalise?: boolean
  updateOnClick?: boolean
  onChange?: (checked: boolean, label: string, value: string) => void
}

const Checkbox: React.FC<Checkbox> = ({
  label,
  size,
  look,
  combo1,
  combo2,
  value,
  checked,
  updateOnClick,
  disabled,
  capitalise,
  onChange,
}: Checkbox) => {
  const [isChecked, setIsChecked] = useState<boolean>(!!checked)
  const checkboxRef = React.useRef<HTMLInputElement>(null)
  const labelRef = React.useRef<HTMLSpanElement>(null)

  useEffect(() => {
    setIsChecked(checked ? true : false)
  }, [checked])

  const handleChange = () => {
    const checked = !isChecked
    updateOnClick && setIsChecked(checked)
    if (onChange && checkboxRef.current && labelRef.current) {
      onChange(checked, labelRef.current.innerText, checkboxRef.current.value)
    }
  }

  const checkboxClasses = classNames(styles.customCheckbox, {
    [styles.radio]: look === 'radio',
  })

  const labelClasses = classNames(styles.label, {
    [styles.withSize]: size && size.length,
    [styles.capitalise]: capitalise,
  })

  const iconClasses = classNames(styles.icon, {
    [styles.iconVisible]: isChecked,
  })

  return (
    <label className={styles.checkbox}>
      <input
        ref={checkboxRef}
        className={styles.input}
        type='checkbox'
        value={value}
        onChange={handleChange}
        checked={checked}
        disabled={disabled}
      />
      <span className={checkboxClasses}>
        <IconTick className={iconClasses} aria-hidden role='presentation' />
      </span>
      <span className={labelClasses}>
        <span ref={labelRef} className={size && styles.sizeTitle}>
          {label}
        </span>
        {size && <span className={styles.sizeText}> {size}</span>}
        {(combo1 || combo2) && (
          <>
            <br />
            <span className={styles.sizeComboText}>
              {combo1 && combo1.length && combo1}
              {combo2 && <br />}
              {combo2 && combo2.length && combo2}
            </span>
          </>
        )}
      </span>
    </label>
  )
}

Checkbox.defaultProps = {
  updateOnClick: true,
}

export default Checkbox
