import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import * as Color from 'color'
import styles from './color-checkbox.module.scss'
import helperStyles from '../../styles/_helpers.module.scss'
import { ReactComponent as IconTick } from 'assets/images/icon-tick.svg'
import { isValidColor, PlateColor } from 'utils'

interface ColorCheckbox {
  color: PlateColor
  checked?: boolean
  disabled?: boolean
  onChange?: (value: string, checked: boolean) => void
}

const ColorCheckbox: React.FC<ColorCheckbox> = ({
  color,
  checked,
  disabled,
  onChange,
}: ColorCheckbox) => {
  const [isChecked, setIsChecked] = useState<boolean>(!!checked)
  const checkboxRef = React.useRef<HTMLInputElement>(null)
  let colorValue = null

  useEffect(() => {
    setIsChecked(!!checked)
  }, [checked])

  if (isValidColor(color.value)) {
    colorValue = Color.default(color.value)
  } else {
    if (process.env.REACT_APP_ENV !== 'production') {
      console.error('Invalid color value:', color.value)
    }
    return null
  }

  const isLightColor = colorValue.isLight()
  const isWhiteColor = colorValue.luminosity() > 0.95 // capture near-white colours.

  const labelClass = classNames(styles.label, {
    [styles.labelLight]: isLightColor,
    [styles.labelDark]: !isLightColor,
    [styles.labelBorder]: isWhiteColor,
    [styles.labelScaled]: isChecked,
  })

  const iconClass = classNames(styles.icon, {
    [styles.iconVisible]: isChecked,
  })

  const handleChange = () => {
    const checked = !isChecked
    setIsChecked(checked)
    if (onChange && checkboxRef.current) {
      onChange(checkboxRef.current.value, checked)
    }
  }

  return (
    <label className={styles.colorCheckbox}>
      <input
        ref={checkboxRef}
        className={styles.checkbox}
        type='checkbox'
        onChange={handleChange}
        value={color.value}
        checked={isChecked}
        disabled={disabled}
      />
      <span className={labelClass} style={{ backgroundColor: color.value }}>
        <span className={helperStyles.visuallyHidden}>{color.name}</span>
        <IconTick className={iconClass} aria-hidden role='presentation' />
      </span>
    </label>
  )
}

export default ColorCheckbox
