import React from 'react'
import styles from './message.module.scss'
import classNames from 'classnames'

interface Message {
  type?: 'error' | 'success' | 'info' | 'warning'
  children?: React.ReactNode
}

export const Message: React.FC<Message> = ({ type, children }: Message) => {
  const messageClasses = classNames(styles.message, {
    [styles.errorMessage]: type === 'error',
    [styles.infoMessage]: type === 'info',
    [styles.warningMessage]: type === 'warning',
    [styles.successMessage]: type === 'success',
  })

  return (
    <div className={messageClasses} key='message'>
      <div className={styles.inner}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  )
}

export default Message
