import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { LocationDescriptorObject } from 'history'
import styles from './button.module.scss'

enum TetriminoPositions {
  'topLeft',
  'topRight',
  'bottomRight',
  'bottomLeft',
}

interface TetriminoIcon {
  position: TetriminoPositions
}

// I ❤ Tetris
const TetriminoIcon: React.FC<TetriminoIcon> = ({ position }: TetriminoIcon) => {
  const classes = classNames(styles.tetrimino, {
    [styles.tetriminoTopLeft]: position === TetriminoPositions.topLeft,
    [styles.tetriminoTopRight]: position === TetriminoPositions.topRight,
    [styles.tetriminoBottomRight]: position === TetriminoPositions.bottomRight,
    [styles.tetriminoBottomLeft]: position === TetriminoPositions.bottomLeft,
  })
  return (
    <span className={classes} aria-hidden>
      <svg width='18' height='12' xmlns='http://www.w3.org/2000/svg'>
        <path d='M7 6v6h2.665V8.836H18V6z' />
      </svg>
    </span>
  )
}

interface Button {
  children: string
  size?: 'large' | 'default'
  to?: LocationDescriptorObject | string
  as?: 'anchor' | 'link' | 'button'
  type?: 'button' | 'submit'
  style?: 'default' | 'outline'
  onClick?: (event: React.SyntheticEvent<HTMLAnchorElement | HTMLButtonElement>) => void
}

type PlateButton = Button & Partial<Pick<HTMLAnchorElement, 'rel' | 'target'>>

const Button: React.FC<PlateButton> = ({
  children,
  to,
  onClick,
  style = 'default',
  size = 'default',
  type = 'button',
  as = 'button',
  ...props
}: PlateButton) => {
  const buttonClasses = classNames(styles.button, {
    [styles.buttonLarge]: size === 'large',
    [styles.buttonOutline]: style === 'outline'
  })

  if (as === 'link' && to) {
    return (
      <Link to={to} className={buttonClasses} {...props}>
        <span>{children}</span>
        <TetriminoIcon position={TetriminoPositions.topLeft} />
        <TetriminoIcon position={TetriminoPositions.topRight} />
        <TetriminoIcon position={TetriminoPositions.bottomRight} />
        <TetriminoIcon position={TetriminoPositions.bottomLeft} />
      </Link>
    )
  } else if (as === 'anchor' && to && typeof to === 'string') {
    return (
      <a href={to} className={buttonClasses} {...props}>
        <span>{children}</span>
        <TetriminoIcon position={TetriminoPositions.topLeft} />
        <TetriminoIcon position={TetriminoPositions.topRight} />
        <TetriminoIcon position={TetriminoPositions.bottomRight} />
        <TetriminoIcon position={TetriminoPositions.bottomLeft} />
      </a>
    )
  } else {
    return (
      <button type={type} onClick={onClick} className={buttonClasses}>
        <span>{children}</span>
        <TetriminoIcon position={TetriminoPositions.topLeft} />
        <TetriminoIcon position={TetriminoPositions.topRight} />
        <TetriminoIcon position={TetriminoPositions.bottomRight} />
        <TetriminoIcon position={TetriminoPositions.bottomLeft} />
      </button>
    )
  }
}

export default Button
