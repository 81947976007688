import React from 'react'
import styles from './step.module.scss'
import { ReactComponent as IconCart } from 'assets/images/icon-cart.svg'
import { ReactComponent as IconCreditCards } from 'assets/images/icon-creditcards.svg'
import { ReactComponent as IconLike } from 'assets/images/icon-like.svg'
import { ReactComponent as IconRoute } from 'assets/images/icon-route.svg'
import { ReactComponent as IconId } from 'assets/images/icon-id.svg'
import { ReactComponent as IconTick } from 'assets/images/icon-tick.svg'
import { RouteComponentProps } from 'react-router-dom'
import classNames from 'classnames'

enum IconTypes {
  'cart',
  'thumbsup',
  'route',
  'id',
  'cards',
}

interface StepParams {
  location: string,
  match:string,
  histroy:string
}

const Icon = (name: keyof typeof IconTypes) => {
  switch (name) {
    case 'cart':
      return <IconCart aria-hidden role='presentation' />
    case 'cards':
      return <IconCreditCards aria-hidden role='presentation' />
    case 'thumbsup':
      return <IconLike aria-hidden role='presentation' />
    case 'route':
      return <IconRoute aria-hidden role='presentation' />
    case 'id':
      return <IconId aria-hidden role='presentation' />
    default:
      if (process.env.REACT_APP_ENV !== 'production') {
        console.warn('Invalid icon name.')
      }
      return null
  }
}

//const Step: React.FC<Step> = ({ children, icon, step}: Step) => {

interface Step extends RouteComponentProps<StepParams> {
  step: number
  mainSeries: string
  children: React.ReactNode
  icon: keyof typeof IconTypes
}

const Step: React.FC<Step> = ( props: Step, {location, history, match}: RouteComponentProps) => {

  //const Interstitial: React.FC<RouteComponentProps> = ({ location, mainSeries}: RouteComponentProps) => {
    //console.log('locarion---',typeof location, location)
  console.log('main prop stepppp---',props.mainSeries, props);

  const step = props.step;
  const icon = props.icon;
  const children = props.children;
  const mainSeries = props.mainSeries;

  let condition = false;
  let mainHowItWorksCheckClasses = "";
  let mainLabelClasses = "";
  let mainCircleClasses = "";
  let mainTickClasses ="";
   if(mainSeries !== ''){
     condition = true;
     mainHowItWorksCheckClasses = classNames( {
       [styles.mainHowItWorksCheckClass]: (condition),
     })
     mainLabelClasses = classNames({
      [styles.mainHowItWorksCheckLabel] : (condition),
     })
     mainCircleClasses = classNames({
      [styles.mainHowItWorksCheckCircle] : (condition),
     })
     mainTickClasses = classNames({
      [styles.mainHowItWorksCheckTick] : (condition),
     })

   }
  const isStepNumber = step && !isNaN(Number(step))
  return (
    <li className={styles.step}>
      <span className={styles.iconContainer+' '+mainHowItWorksCheckClasses}>{Icon(icon)}</span>
      {!isStepNumber && (<span className={styles.circle+' '+' '+mainTickClasses+' '+styles.mainTick+' '+mainCircleClasses} >
        <IconTick className={styles.tick} aria-hidden role='presentation' />
      </span>)}
      {isStepNumber !== 0 && (<span className={styles.circle+' '+mainCircleClasses} data-step-number={step} >
      </span>)}
      <span className={styles.label+' '+mainLabelClasses}>{children}</span>
    </li>
  )
}

export default Step
