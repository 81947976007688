import React, { useState, useEffect } from 'react'
import styles from './price-range.module.scss'
import { Range, getTrackBackground } from 'react-range'
import { toMoney } from 'utils'
import { IProps } from 'react-range/lib/types'
import helperStyles from 'styles/_helpers.module.scss'
import classNames from 'classnames'

interface PriceRange {
  min: IProps['min']
  max: IProps['max']
  defaultValues: IProps['values']
  step?: number
  onChange?: IProps['onChange']
}

/**
 * Component that returns a price range input with a min and max value 'thumb'.
 */
const PriceRange: React.FC<PriceRange> = ({
  min,
  max,
  step,
  defaultValues,
  onChange,
}: PriceRange) => {
  const [range, setRange] = useState<{ values: IProps['values'] }>({
    values: defaultValues || [min, max],
  })
  const titleId = 'priceFilterTitle'

  useEffect(() => {
    if (defaultValues) {
      setRange({ values: defaultValues })
    }
  }, [defaultValues])

  const handleChange: IProps['onChange'] = values => {
    if (onChange) {
      onChange(values)
    }
    setRange({ values })
  }

  if (isNaN(min) || isNaN(max) || !(defaultValues && defaultValues.length)) {
    return null
  }

  return (
    <div className={styles.priceRange}>
      <h3 className={styles.title} id={titleId}>
        Price
      </h3>
      <Range
        allowOverlap={false}
        step={step}
        min={min}
        max={max}
        values={range.values}
        onChange={handleChange}
        renderTrack={({ props, children }) => (
          <div className={styles.trackOuter} style={{ ...props.style }}>
            <div
              {...props}
              ref={props.ref}
              className={styles.trackInner}
              style={{
                background: getTrackBackground({
                  values: range.values,
                  colors: [
                    styles.trackBackgroundColor,
                    styles.trackForegroundColor,
                    styles.trackBackgroundColor,
                  ],
                  min,
                  max,
                }),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged, index }) => (
          <div
            {...props}
            aria-labelledby={
              index === 0 ? `${titleId} priceFilterMin` : `${titleId} priceFilterMax`
            }
            className={classNames(styles.thumb, {
              [styles.thumbActive]: isDragged,
            })}
            style={{
              ...props.style,
              backgroundColor: isDragged ? styles.thumbDraggedColor : styles.thumbColor,
            }}
          />
        )}
      />
      <div className={styles.output}>
        <output>
          <span id='priceFilterMin' aria-label='Min. ' className={helperStyles.visuallyHidden}>
            From{' '}
          </span>
          {toMoney(range.values[0])}
          <span id='priceFilterMax' aria-label=' max. '>
            {' '}
            &ndash;{' '}
          </span>
          {toMoney(range.values[1])}
        </output>
      </div>
    </div>
  )
}

PriceRange.defaultProps = {
  step: 10,
}

export default PriceRange
