import React, { useState, useContext, useEffect, useCallback } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import classNames from 'classnames'
import { routes } from 'App'
import {
  useMedia,
  Plate as PlateData,
  generatePriceRangeMinMaxValues,
  PriceRangeMinMaxValues,
  IFilter,
  PlateColor,
} from 'utils'
import breakpoints from 'styles/_breakpoints.module.scss'
import Container from 'components/container'
import Header from 'components/header'
import Footer from 'components/footer'
import Button from 'components/button'
import PriceRange from 'components/price-range'
import PlateGrid from 'components/plate-grid'
import ColorPicker from 'components/color-picker'
import ChipDisplay from 'components/chip-display'
import Interstitial from 'components/interstitial'
import Checkbox from 'components/checkbox'
import styles from './filter.module.scss'
import FilterHeader from './filter-header'
import FilterOption from './filter-option'
import { AppContext } from 'state'
import { generateFilters, generatePlates, generateColors, generateSizes } from 'utils'
import { debounce } from 'utils/debounce'
import {
  Footer as FooterProp,
  Header as HeaderProp
} from 'state/types'

interface FilterViewMatchParams {
  series: string,
  location: string
}

interface FilterView extends RouteComponentProps<FilterViewMatchParams> { }

const FilterView: React.FC<FilterView> = (props: FilterView, {location, history, match}: RouteComponentProps) => {
  const { platesJson } = useContext(AppContext)
  console.log('filter props-----',props, props.location.pathname)

  const defaultFilter: IFilter = {
    material: [],
    colors: [],
    vehicle: 'car',
    series: props.match.params.series || '',
    patterns: '',
    sizes: [],
    priceRange: [],
  }

  const isTablet = useMedia(`(min-width: ${breakpoints.tablet})`)
  const [filterSidebarOpen, setFilterSidebarOpen] = useState<boolean>(false)
  const state = useContext(AppContext)
  const [chips, setChips] = useState<string[]>([])
  const [colors, setColors] = useState<PlateColor[]>([])
  const [vehicles, setVehicles] = useState<any[]>([])
  const [materials, setMaterials] = useState<any[]>([])
  const [priceRangeValues, setPriceRangeValues] = useState<PriceRangeMinMaxValues>()
  const [sizes, setSizes] = useState<any[]>([])
  const [filter, setFilter] = useState<IFilter>(defaultFilter)
  const [filteredPlates, setFilteredPlates] = useState<PlateData[] | null>(null)
  const [filterReset, setFilterReset] = useState<boolean>(false)
  const [footer, setFooter] = useState<FooterProp | null>(
    (platesJson.footer) || null
  )
  const [header, setHeader] = useState<HeaderProp | null>(
    (platesJson.header) || null
  )

  const filterSidebarClasses = classNames(styles.filterSidebar, {
    [styles.filterSidebarOpen]: filterSidebarOpen || isTablet,
  })

  const gridClasses = classNames(styles.plateGrid, {
    [styles.noResults]: !(filteredPlates && filteredPlates.length),
  })

  const handlePriceRangeChange = async (values: number[]) => {
    const updatedFilter = { ...filter, priceRange: values }
    setFilter(updatedFilter)
    filterPlates(updatedFilter)
  }

  const handleColorPick = (props: { color: string; checked: boolean }) => {
    updateFilter('colors', props.color, props.checked)
  }

  const handleVehiclePick = (vehicle: string) => {
    filter.sizes = []
    setFilter(filter)
    updateFilter('vehicle', vehicle)
  }

  const handleChipRemove = (event: React.SyntheticEvent<HTMLDivElement>) => {
    const text = event.currentTarget.textContent || ''
      ; (Object.keys(filter) as Array<keyof typeof filter>).forEach(filterKey => {
        const filteredKey = filter[filterKey] as string[]
        if (Array.isArray(filteredKey) && filteredKey.includes(text)) {
          updateFilter(filterKey, text, false)
        } else if (typeof filteredKey === 'string') {
          // Nice to have: Handle series filter removal (requires nicer chip names)
          // Nice to have: update filter here causes bug where some chips will not be
          // removed on click due to running the updateFilter function in quick succession.
          // updateFilter(filterKey, '')
          // Nice to have: Needs to update the current route as well without the series
        }
      })
  }

  const plateTextSearch = (plateText: string) => updateFilter('patterns', plateText)

  const isPatternMatch = (pattern: string, val: string): boolean => {
    const regex = new RegExp(pattern)
    return typeof val === 'string' && regex.test(val)
  }

  const filterPlates = useCallback(
    (updatedFilter: IFilter) => {
      if (!state.platesJson.series) return null
      const plates = generatePlates(state.platesJson.series)
      if (!plates) return null
      const matchingPlates = plates.filter(plate => {
        let filterMatch = true
        let patternMatch = true
        for (let [key, val] of Object.entries(updatedFilter) as [
          keyof PlateData,
          string | number | boolean | []
        ][]) {
          if (plate[key] !== undefined && val) {
            // filter via regex
            if (key === 'patterns') {
              patternMatch = false
              for (const pattern of Object.values(plate.patterns)) {
                if (isPatternMatch(pattern, val as string)) {
                  patternMatch = true
                }
              }
            } else {
              if (Array.isArray(val) && typeof plate[key] === 'string') {
                if (val.length > 0 && !(val as any).includes(plate[key])) {
                  filterMatch = false
                }
              } else if (Array.isArray(plate[key]) && typeof val === 'string') {
                if ((plate[key] as []).length > 0 && !(plate[key] as any).includes(val)) {
                  filterMatch = false
                }
              } else if (Array.isArray(val) && Array.isArray(plate[key])) {
                if (val.length > 0 && !val.filter(v => (plate[key] as []).includes(v)).length) {
                  filterMatch = false
                }
              } else if (plate[key] !== val) {
                filterMatch = false
              }
            }
          } else {
            if ((key as keyof IFilter) === 'priceRange' && updatedFilter.priceRange.length) {
              if (
                plate.start_price < updatedFilter.priceRange[0] ||
                plate.start_price > updatedFilter.priceRange[1]
              )
                filterMatch = false
            }
          }
        }
        if (filterMatch && patternMatch) {
          return plate
        }
        return false
      })
      setFilteredPlates(matchingPlates)
    },
    [state.platesJson]
  )

  const updateFilter = useCallback(
    (key: keyof IFilter, value: string | number, checked?: boolean) => {
      const updatedFilter = { ...filter } as any
      const filterType = Array.isArray(updatedFilter[key]) ? 'array' : typeof updatedFilter[key]
      switch (filterType) {
        case 'string':
          updatedFilter[key] = value
          break
        case 'array':
          let updatedArray = updatedFilter[key].slice(0)
          if (checked) {
            updatedArray.push(value)
          } else {
            updatedArray = updatedArray.filter((v: string) => v !== value)
          }
          updatedFilter[key] = Array.from(new Set(updatedArray))
          break
        default:
          process.env.REACT_APP_ENV === 'development' &&
            updatedFilter[key] === undefined &&
            console.error(`${key} not found in filter object`)
      }
      setFilter(updatedFilter)
      filterPlates(updatedFilter)
    },
    [filter, filterPlates]
  )

  const handleFilterSidebarOpen = () => {
    if (!(isTablet && filterSidebarOpen)) {
      setFilterSidebarOpen(true)
      const filterOptionHeading = document.querySelector<HTMLHeadingElement>('#filterOptionsTitle')
      filterOptionHeading && filterOptionHeading.focus()
    }
  }

  const handleFilterSidebarClose = () => {
    if (!isTablet && filterSidebarOpen) {
      setFilterSidebarOpen(false)
      const filterToggleButton = document.querySelector<HTMLButtonElement>('#filterToggleButton')
      filterToggleButton && filterToggleButton.focus()
    }
  }

  const handleClearClick = () => {
    if (priceRangeValues) {
      // Trigger reset for ColorCheckbox
      setFilterReset(!filterReset)
      // Trigger reset for PriceRange
      const priceRangeMinMaxValues = generatePriceRangeMinMaxValues(state.platesJson.series)
      if (priceRangeMinMaxValues) {
        setPriceRangeValues(priceRangeMinMaxValues)
      }
      // Reset the rest of the filters...
      const updatedFilter = {
        ...defaultFilter,
        priceRange: priceRangeValues.defaultValues,
      }
      setFilter(updatedFilter)
      filterPlates(updatedFilter)
    }
  }

  const generateChips = useCallback(() => {
    let chipsToDisplay: string[] = []
    if (filter.material && filter.material.length) {
      chipsToDisplay = chipsToDisplay.concat(filter.material)
    }
    if (filter.sizes && filter.sizes.length) {
      chipsToDisplay = chipsToDisplay.concat(filter.sizes)
    }
    // Nice to have: Handle series filter removal (requires nicer chip names)
    // if (filter.series && filter.series.length) {
    //   chipsToDisplay = chipsToDisplay.concat(filter.series)
    // }
    return chipsToDisplay
  }, [filter])

  useEffect(() => {
    setFilteredPlates(generatePlates(state.platesJson.series))
    setColors(generateColors(generateFilters(state.platesJson.series, 'colors')))
    setVehicles(generateFilters(state.platesJson.series, 'sizes', false, true))
    setMaterials(generateFilters(state.platesJson.series, 'material'))
    const priceRangeMinMaxValues = generatePriceRangeMinMaxValues(state.platesJson.series)
    if (priceRangeMinMaxValues) {
      setPriceRangeValues(priceRangeMinMaxValues)
      const updatedFilter = { ...filter, priceRange: priceRangeMinMaxValues.defaultValues }
      setFilter(updatedFilter)
      filterPlates(updatedFilter)
    }
    if (platesJson.header) {
      console.log('main footer---',platesJson.header.FAQs_link)
      setHeader(platesJson.header)
    }
    if (platesJson.footer) {
      console.log('main footer---',platesJson.footer.PrivacyPolicy_link)
      setFooter(platesJson.footer)
    }
    // eslint-disable-next-line
  }, [state.platesJson])

  useEffect(() => {
    setSizes(generateSizes(state.platesJson.series, filter.vehicle, filter.series))
    setChips(generateChips())
  }, [state.platesJson, filter, generateChips])

  useEffect(() => {
    updateFilter('series', props.match.params.series || '')
    // eslint-disable-next-line
  }, [props.match.params.series])

  const isDebug =
    (process.env.REACT_APP_ENV !== 'production' && process.env.REACT_APP_DEBUG === 'true') ||
    window.location.hash.includes('debug=1')

  const mainSeries = (props.match.params.series)? props.match.params.series : props.match.path;
  console.log('mainSeries filter----------',mainSeries,props)

  return (
    <>
      <Container full className={styles.headerBg}>
        {/* <Container> */}
        {header && <Header {...header} />}
        {/* </Container> */}
      </Container>
      {/* <Container> */}
        <main role='main'>
          <div className={styles.layout}>
            <section className={filterSidebarClasses} aria-hidden={!isTablet && !filterSidebarOpen}>
              <form noValidate className={styles.filterForm}>
                <div className={styles.filterOptions}>
                  <h2
                    id='filterOptionsTitle'
                    tabIndex={!isTablet ? 0 : undefined}
                    className={styles.filterHeading}
                  >
                    Filter Options
                  </h2>
                  <FilterOption>
                    {priceRangeValues &&
                      !isNaN(priceRangeValues.min) &&
                      !isNaN(priceRangeValues.max) && (
                        <PriceRange
                          defaultValues={priceRangeValues.defaultValues}
                          min={priceRangeValues.min}
                          max={priceRangeValues.max}
                          onChange={debounce(handlePriceRangeChange, 300)}
                        />
                      )}
                  </FilterOption>
                  <FilterOption title='Vehicle Type'>
                    <ul>
                      {vehicles.map((vehicle : string)=> (
                        <li key={vehicle}>
                          <Checkbox
                            capitalise
                            value={vehicle}
                            label={vehicle}
                            //look='radio'
                            checked={filter.vehicle === vehicle}
                            updateOnClick={false}
                            onChange={handleVehiclePick.bind(null, vehicle)}
                          />
                        </li>
                      ))}
                    </ul>
                  </FilterOption>
                  <FilterOption title='Material'>
                    <ul>
                      {materials.map((material: string) => (
                        <li key={material}>
                          <Checkbox
                            capitalise
                            checked={filter.material.includes(material)}
                            value={material}
                            label={material}
                            onChange={updateFilter.bind(null, 'material', material)}
                          />
                        </li>
                      ))}
                    </ul>
                  </FilterOption>
                  <FilterOption title='Colour'>
                    <ColorPicker
                      colors={colors}
                      onClickColor={handleColorPick}
                      reset={filterReset}
                    />
                  </FilterOption>
                  <FilterOption title='Size'>
                    <ul>
                      {Object.values(sizes).map((size, i) => {
                        return (
                          <li key={i}>
                            <Checkbox
                              value={size.title}
                              label={size.title}
                              size={size.combo1 && size.combo2 ? undefined : size.size}
                              combo1={size.combo1 ? size.combo1 : undefined}
                              combo2={size.combo2 ? size.combo2 : undefined}
                              checked={filter.sizes.includes(size.title)}
                              updateOnClick={false}
                              onChange={updateFilter.bind(null, 'sizes', size.title)}
                            />
                          </li>
                        )
                      })}
                    </ul>
                  </FilterOption>
                </div>
                <div className={styles.clearfilterBtn}>
                  <Button onClick={handleClearClick} style='default'>
                    Clear filters
                  </Button>
                </div>
                <div className={styles.filterSidebarButtonsContainer} aria-hidden={isTablet}>
                  <div className={styles.filterSidebarButtons}>
                    <button type='button' className={styles.clearButton} onClick={handleClearClick}>
                      Clear
                    </button>
                    <Button onClick={handleFilterSidebarClose}>Apply</Button>
                  </div>
                </div>
              </form>
            </section>
            <section className={styles.plateCreator} onClick={handleFilterSidebarClose}>
              <FilterHeader seriesName={state.platesJson.series?.find(item => item.series === props.match.params.series)?.name} rulesLink={state.platesJson.assets && state.platesJson.assets.rules_link} onPlateSearch={debounce(plateTextSearch, 400)} />
              <hr/>
              {/* <ChipDisplay
                chips={chips}
                onRemove={handleChipRemove}
                className={styles.chipDisplay}
              /> */}


              {isDebug && filteredPlates && (
                <>
                  <div style={{ background: 'black', padding: '20px', color: 'white' }}>
                    {JSON.stringify(filter)}
                    <br />
                    <br />
                    Total: {filteredPlates.length}
                  </div>
                  <ul>
                    {filteredPlates.map((plate, i) => {
                      const { colors, name } = plate
                      return (
                        <li key={i} style={{ float: 'left', padding: '2rem', width: '50%' }}>
                          <h4 style={{ margin: 0 }}>{name}</h4>
                          <table style={{ fontSize: '1.3rem' }}>
                            <tbody>
                              {(Object.keys(plate) as Array<keyof typeof plate>).map(
                                (key, n: number) => {
                                  return (
                                    <tr key={n}>
                                      <td>{key}</td>
                                      <td>
                                        {key === 'series_colors' && (
                                          <>
                                            {plate[key].map((color: PlateColor, i: number) => (
                                              <div
                                                key={i}
                                                style={{
                                                  width: '20px',
                                                  height: '20px',
                                                  background: color.value,
                                                  fontSize: '20px',
                                                }}
                                              >
                                                {colors.map(c => c === color.value && 'x')}
                                              </div>
                                            ))}
                                          </>
                                        )}
                                        {key !== 'series_colors' && <>{`${(plate as any)[key]}`}</>}
                                      </td>
                                    </tr>
                                  )
                                }
                              )}
                            </tbody>
                          </table>
                        </li>
                      )
                    })}
                  </ul>
                  <br style={{ clear: 'both' }} />
                </>
              )}
              {props.location.pathname === '/choose-your-style' && (
                <>
                  {/* <Interstitial location={props.location.search} mainSeries={props.location.pathname}/> */}
                  <Interstitial location={location} history={history as any} match={match as any} mainSeries={props.location.pathname} />
                  <hr />
                </>
              )}
              <div className={styles.filterSidebarToggleContainer}>
                <button
                  className={styles.filterSidebarToggle}
                  id='filterToggleButton'
                  onClick={handleFilterSidebarOpen}
                  type='button'
                >
                  Filter:
                </button>
              </div>
              <div className={styles.mainGridPlatinumClass}>
                <PlateGrid plates={filteredPlates} displayCount className={gridClasses} mainSeries={mainSeries} />
                { props.location.pathname !== '/choose-your-style' && mainSeries !== 'euro_style' && mainSeries !== "platinum" && (
                  <>
                  <hr />
                  <div className={styles.plateDesignSection}>
                    <h3 className={styles.plateDesignTitle}>Plate design features</h3>
                    { mainSeries === 'design' && (
                      <div><span style={{fontSize: "1.5rem"}}>No space permitted. Plates are manufactured with a space between the letters and numbers. Can contain:</span>
                      <ul style={{fontSize: "1.5rem", margin: "0"}}>
                        <li>3 letters and 3 numbers; or </li>
                        <li>3 numbers and 3 letters</li>
                      </ul></div>
                    )}
                    { mainSeries === 'retro' && (
                      <div>
                        <span style={{fontSize: "1.5rem"}}>No space permitted.</span><br/>
                        <span style={{fontSize: "1.5rem", textDecoration: 'underline'}}>Motor vehicles</span>
                        <ul style={{fontSize: "1.5rem", margin: "0"}}>
                          <li>start with U or X and 3 letters and 3 numbers.</li>
                        </ul>
                        <span style={{fontSize: "1.5rem", textDecoration: 'underline'}}>Motorcycles</span>
                        <ul style={{fontSize: "1.5rem", margin: "0"}}>
                          <li>start with U or X and 2 letters and 3 numbers; or</li>
                          <li>start with U and 2 letters and 4 numbers.</li>
                        </ul>
                      </div>
                    )}
                    { mainSeries === 'prestige' && (
                      <div><span style={{fontSize: "1.5rem"}}>No space permitted. Must end with WA and contain:</span>
                      <ul style={{fontSize: "1.5rem", margin: "0"}}>
                        <li>2 letters and 2 numbers.</li>
                      </ul></div>
                    )}
                    { mainSeries === 'frangipani' && (
                      <div><span style={{fontSize: "1.5rem"}}>No space permitted. Must contain:</span>
                      <ul style={{fontSize: "1.5rem", margin: "0"}}>
                        <li>3 letters and 3 numbers; or </li>
                        <li>3 numbers and 3 letters</li>
                      </ul></div>
                    )}
                    { mainSeries === 'chinese_zodiac' && (
                      <div><span style={{fontSize: "1.5rem"}}>No space permitted. Must contain:</span>
                      <ul style={{fontSize: "1.5rem", margin: "0"}}>
                        <li>3 letters and 3 numbers; or </li>
                        <li>2 letters and 4 numbers.</li>
                      </ul></div>
                    )}
                    { mainSeries === 'personalised' && (
                      <div><span style={{fontSize: "1.5rem"}}>No space permitted. Must contain:</span>
                      <ul style={{fontSize: "1.5rem", margin: "0"}}>
                        <li>1, 2 or 3 letters; and </li>
                        <li>end with 3 numbers.</li>
                      </ul></div>
                    )}
                    { mainSeries === 'bizplate' && (
                      <div><span style={{fontSize: "1.5rem"}}>No space permitted. Will have top sash. Can have image (library, own logo, own photo). Must contain:</span>
                      <ul style={{fontSize: "1.5rem", margin: "0"}}>
                        <li>3 letters and 3 numbers.</li>
                      </ul></div>
                    )}
                    { mainSeries === 'sport' && (
                      <div><span style={{fontSize: "1.5rem"}}>No space permitted. Must end with WRC and contain:</span>
                      <ul style={{fontSize: "1.5rem", margin: "0"}}>
                        <li>a maximum of 3 letters or numbers; or</li>
                        <li>a combination of letters and numbers.</li>
                      </ul></div>
                    )}
                    { mainSeries === 'name' && (
                      <div><span style={{fontSize: "1.5rem"}}>No leading space. Must contain:</span>
                      <ul style={{fontSize: "1.5rem", margin: "0"}}>
                        <li>a maximum of 9 letters, including spaces.</li>
                      </ul></div>
                    )}
                    { mainSeries === 'custom' && (
                      <div>
                        <span style={{fontSize: "1.5rem"}}>No leading space. Cannot contain numbers only. May contain all letters, or a combination of letters and numbers.</span>
                        <ul style={{fontSize: "1.5rem", margin: "0"}}>
                          <li>a maximum of 7 characters including spaces.</li>
                        </ul>
                      </div>
                    )}
                    { mainSeries === 'custom_acrylic' && (
                      <div>
                        <span style={{fontSize: "1.5rem"}}>No leading space. Cannot contain numbers only. May contain all letters, or a combination of letters and numbers.</span>
                        <ul style={{fontSize: "1.5rem", margin: "0"}}>
                          <li>maximum of 9 characters for motor vehicles and trailers including spaces.</li>
                          <li>maximum of 7 characters for motorcycle including spaces.</li>
                        </ul>
                        <span style={{fontSize: "1.5rem", textDecoration: 'underline'}}>Brand WA</span>
                        <ul style={{fontSize: "1.5rem", margin: "0"}}>
                          <li>Brand WA only available in normal size.</li>
                          <li>Not available for motorcycle, slimline, 1/2 or 3/4.</li>
                        </ul>
                      </div>
                    )}
                    { mainSeries === 'metallic' && (
                    <div><span style={{fontSize: "1.5rem"}}>No leading space. Cannot contain numbers only. Must contain:</span>
                      <ul style={{fontSize: "1.5rem", margin: "0"}}>
                        <li>a maximum of 7 characters for motor vehicles.</li>
                        <li>a maximum of 7 characters for normal motorcycle.</li>
                        <li>a maximum of 5 characters for small motorcycle.</li>
                      </ul></div>
                    )}
                    { mainSeries === 'euro_series' && (
                      <div>
                        <span style={{fontSize: "1.5rem"}}>No space permitted. Must start with W and end with A and contain:</span><br/>
                        <ul style={{fontSize: "1.5rem", margin: "0"}}>
                          <li>2 letters and 3 numbers.</li>
                        </ul>
                      </div>
                    )}
                    {mainSeries === 'euro_premium' && (
                      <div><span style={{fontSize: "1.5rem"}}>No space permitted. Must start with W and end in Z and contain:</span>
                        <ul style={{fontSize: "1.5rem", margin: "0"}}>
                          <li>a maximum of 7 letters; or</li>
                          <li>a maximum of 7 letters and numbers.</li>
                        </ul>
                      </div>
                    )}
                    {mainSeries === 'euro_custom' && (
                      <div>
                        <span style={{fontSize: "1.5rem"}}>Spaces permitted. Cannot contain numbers only. Must start with W and contain:</span>
                        <ul style={{fontSize: "1.5rem", margin: "0"}}>
                          <li>2 letters and 3 numbers.</li>
                        </ul>
                      </div>
                    )}
                    {mainSeries === 'euro_custom_plus' && (
                      <div>
                        <span style={{fontSize: "1.5rem"}}>No space permitted. Must start with W and contain:</span>
                        <ul style={{fontSize: "1.5rem", margin: "0"}}>
                          <li>3 letters and 4 numbers; or </li>
                          <li>4 letters and 3 numbers.</li>
                        </ul>
                      </div>
                    )}
                    { mainSeries === 'intense' && (
                    <div><span style={{fontSize: "1.5rem"}}>No space permitted. Plates will be manufactured with a space between the letters and numbers. Must contain:</span>
                      <ul style={{fontSize: "1.5rem", margin: "0"}}>
                        <li>2 letters and 4 numbers.</li>
                        <li>3 letters and 2 numbers.</li>
                        <li>3 letters and 3 numbers.</li>
                        <li>4 letters and 2 numbers.</li>
                      </ul></div>
                    )}
                    <Interstitial location={location} history={history as any} match={match as any} mainSeries={mainSeries} />
                    {/* <Interstitial location={props.location.search} mainSeries={mainSeries}/> */}
                  </div>
                  </>
                  )}
                <div className={styles.purchaseButton}>
                  <PurchaseButton plates={filteredPlates} />
                </div>
              </div>
            </section>
          </div>
        </main>
      {/* </Container> */}
      <Container full className={styles.footerBg}>
          {footer && <Footer {...footer} />}
        </Container>
    </>
  )
}

interface PurchaseButton {
  plates: PlateData[] | null
}

const PurchaseButton: React.FC<PurchaseButton> = ({ plates }: PurchaseButton) => {
  console.log('main platesss-----',plates)
  // We only want to show the "Continue to purchase" button if the user has narrowed down
  // the selection to a single series.
  if (!plates || !plates.length) {
    return null
  }

  // Grab the `trelis_type` and `store_front` of the first plate in the filtered plates.
  const { trelis_type, store_front } = plates[0]
  if (!trelis_type || !trelis_type.length) {
    return null
  }

  // Compare all plates in the filtered plates as we only want 1 trelis type.
  const uniqueSeries = plates.every(plate => plate.trelis_type === trelis_type)
  if (!uniqueSeries) return null

  // Check if plate can only be ordered through a service centre (store front).
  if (!!store_front.text.length && !!store_front.url.length) {
    console.log('plates--',plates[0].store_front.text1, plates[0].store_front.url)
    //return nulln
    return (
      <div className={styles.plateDesignSection}>

        <span className={styles.plateDesignSectionSpan}>
          {plates[0].store_front.text1} <br/> {plates[0].store_front.text2} <a href={plates[0].store_front.url} style={{color:"#000000"}}>metropolitan and regional centres.</a>
        </span>
        <Button
          size='large'
          as='link'
          to={`${routes.filter.path}`}
        >
          Back to all plates
        </Button>
      </div>
    )
  }

  return (
    <div className={styles.plateDesignSection}>
      <Button
        size='large'
        as='link'
        to={`${routes.filter.path}`}
        // to={{
        //   pathname: routes.interstitial.path,
        //   search: `?type=${trelis_type}`,
        // }}
      >
        Back to all plates
      </Button>
    </div>
  )
}

export default FilterView
