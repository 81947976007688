import React from 'react'
import { Link } from 'react-router-dom'
import styles from './logo.module.scss'
import helperStyles from 'styles/_helpers.module.scss'
import logo from 'assets/images/plates-logo.svg'
import { routes } from 'App'

interface Logo {
  linkTo?: string
  mainData: string
}

const Logo: React.FC<Logo> = ({ linkTo, mainData }: Logo) => (
  <Link to={linkTo ? linkTo : routes.index.path} className={styles.link}>
    <h1 className={helperStyles.visuallyHidden}>Plates WA</h1>
    {mainData === 'footer' && (
      <img src={logo} className={styles.logoFooter} alt={mainData} role='presentation' aria-hidden />
    )}
    {mainData === 'header' && (
      <img src={logo} className={styles.logo} alt={mainData} role='presentation' aria-hidden />
    )}
  </Link>
)

export default Logo
