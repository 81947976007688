import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { parse as parseQuery } from 'query-string'
import { routes } from 'App'
import Header from 'components/header'
import Button from 'components/button'
import Step from 'components/step'
import styles from './interstitial.module.scss'
import classNames from 'classnames'

const buildTrelisQueryString = (searchString: string): string => {
  let queryString = ''
  /**
   * We only care about the `type` paremeter in the query string.
   * However, a query string can be a `string` or `string[]`.
   * Example:
   *   ?type=string1&type=string2&type=string3
   *   ?type=string1,string2
   *
   * In either case, we only deal with the **first** item.
   * All other paremeters are discarded when we forward to Trelis.
   *
   * Nice to have: Check if `type` is one that we recognise based on the Plates JSON data.
   */
  const query = parseQuery(searchString)
  if (query.type && query.type.length) {
    const { type } = query
    if (Array.isArray(type)) {
      queryString = `?type=${type[0]}`
    } else {
      const typeStringArray = type.split(',')
      if (typeStringArray.length > 1) {
        queryString = `?type=${typeStringArray[0]}`
      } else {
        queryString = `?type=${type}`
      }
    }
  }
  return queryString
}

interface InterinsicMatchParams {
  series: string,
  location: string
}

interface Interstitial extends RouteComponentProps<InterinsicMatchParams> {
  mainSeries: string;
}

const Interstitial: React.FC<Interstitial> = (props: Interstitial, {location, history, match}: RouteComponentProps) => {

//const Interstitial: React.FC<RouteComponentProps> = ({ location, mainSeries}: RouteComponentProps) => {
  //console.log('locarion---',typeof location, location)
  console.log('main prop---',props.mainSeries, props)
  const mainSeries = props.mainSeries;
  // const location = mainSeries;
  // const trelisQueryString = buildTrelisQueryString(location)
 let condition = false;
 let choose_styleClass = "";
 let choose_howItWorksCheckLeftClass = "";
 let mainLiClasses = "";
  if(mainSeries !== ''){
    condition = true;
    choose_styleClass = classNames({
      [styles.choose_style]: (condition),
    })
    mainLiClasses = classNames({
      [styles.mainHowItWorksCheckLI] : (condition),
     })
  }
  if(mainSeries === '/choose-your-style'){
    condition = true;
    choose_howItWorksCheckLeftClass = classNames({
      [styles.choose_howItWorksCheckLeft]: (condition),
    })
  }


  return (
    <>

      {/* <Container full className={styles.headerBg}>
        <Container>
          <Header />
        </Container>
      </Container> */}
      <main role='main' style={{textAlign:"center"}} className={choose_styleClass+' '+choose_howItWorksCheckLeftClass}>
        {/* <Container full className={styles.subHeader}>
          <Container>
            <h1>Let&rsquo;s make your design a reality</h1>
          </Container>
        </Container> */}
        {/* { mainSeries == 'metallic' && ( */}
          {/* <Container className={styles.body}> */}
            <h2 className={styles.h2Howitworks}>How it works</h2>
            <ol className={styles.stepList+' '+mainLiClasses}>
              <Step step={1} icon='cart' mainSeries={mainSeries} location={location} history={history as any} match={match as any} >
                Order online
              </Step>
              <Step step={2} icon='thumbsup' mainSeries={mainSeries} location={location} history={history as any} match={match as any}>
                We review &amp; make
                <br />
                your plates
              </Step>
              <Step step={3} icon='route' mainSeries={mainSeries} location={location} history={history as any} match={match as any}>
                Collect the plates from us when they are ready
              </Step>
            </ol>
            {/* <hr className={styles.separator} /> */}
            { mainSeries !== '/choose-your-style' && (
              <div className={styles.mainHowWorkSec}>
                <h2 className={styles.h2Howitworks}>What you&rsquo;ll need</h2>
                <ul className={styles.stepList}>
                  <Step icon='id' mainSeries={mainSeries} location={location} history={history as any} match={match as any} step={0}>Your valid drivers licence</Step>
                  <Step icon='cards' mainSeries={mainSeries} location={location} history={history as any} match={match as any} step={0}>Credit/debit card</Step>
                </ul>
                { mainSeries === '' && (
                <div className={styles.action}>
                  <Button
                    as='link'
                    //to={`${process.env.REACT_APP_TRELIS_URL}/${trelisQueryString}`}
                    to={`${routes.filter.path}`}
                    rel='noopener noreferrer'
                    size='large'
                  >
                    Let's do this
                  </Button>
                </div>
                )}
              </div>
            )}
          {/* </Container> */}
        {/* )} */}
      </main>
    </>
  )
}

export default Interstitial
