import React, { useState, useEffect } from 'react'
import styles from './color-picker.module.scss'
import ColorCheckbox from 'components/color-checkbox'
import { PlateColor, sortColorsByContrast } from 'utils'

interface ColorPicker {
  colors: PlateColor[]
  onChange?: (selectedColors: PlateColor['name'][]) => void
  onClickColor?: (props: { color: string; checked: boolean }) => void
  reset?: boolean
}

const ColorPicker: React.FC<ColorPicker> = ({
  colors,
  onChange,
  onClickColor,
  reset,
}: ColorPicker) => {
  const [state, setState] = useState<string[]>([])

  useEffect(() => {
    setState([])
  }, [reset])

  if (!colors || !colors.length) return null

  const sortedColors = sortColorsByContrast(colors)

  const handleStateChange = (val: string, checked: boolean) => {
    let newState
    if (val && !checked) {
      newState = [...state].filter(el => el !== val)
    } else {
      newState = [...state, val]
    }
    setState(newState)
    onChange && onChange(newState)
    onClickColor && onClickColor({ color: val, checked })
  }

  return (
    <ol className={styles.list}>
      {sortedColors.map((color, index) => (
        <li className={styles.listItem} key={`color${index}-${color.name}`}>
          <ColorCheckbox
            color={color}
            onChange={handleStateChange}
            checked={state.includes(color.value)}
          />
        </li>
      ))}
    </ol>
  )
}

export default ColorPicker
