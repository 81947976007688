import React, { useContext, useEffect, useState } from 'react'
import Container from 'components/container'
import Header from 'components/header'
import Footer from 'components/footer'
import StretchImage from 'components/stretch-image'
import Skyscraper from 'components/skyscraper'
import Testimonial from 'components/testimonial'
import Button from 'components/button'
import Hero from 'components/hero'
import styles from './landing.module.scss'
import PlateGrid from 'components/plate-grid'
import Interstitial from 'components/interstitial'
import { AppContext } from 'state'
import { RouteComponentProps } from 'react-router-dom'
import { routes } from 'App'

import {
  Testimonial as TestimonialProps,
  Stretch,
  Hero as HeroProps,
  Skyscraper as SkyscraperProps,
  Footer as FooterProp,
  Header as HeaderProp
} from 'state/types'
import { generatePlates, Plate, sortPlatesByName } from 'utils'


const getPopularPlates = (plateData: Plate[] | null): Plate[] | null => {
  if (!plateData) return null
  return plateData.filter(series => series.popular)
}

const getUniqueSeriesPlates = (plateData: Plate[] | null) => {
  if (!plateData) return null
  // first we sort
  const sortedSeries = sortPlatesByName(plateData)
  // then we dedupe
  return sortedSeries.reduce<Plate[]>((uniqueSeries, current) => {
    const match = uniqueSeries.find(series => series.series === current.series)
    if (!match) {
      return uniqueSeries.concat([current])
    } else {
      return uniqueSeries
    }
  }, [])
}


const getRandomTestimonial = (testimonials: TestimonialProps[]): TestimonialProps | null => {
  if (testimonials && testimonials.length) {
    const randomNumber: number = Math.floor(Math.random() * testimonials.length - 1) + 1
    return testimonials[randomNumber]
  }
  return null
}

interface LandingViewMatchParams {
  series: string,
  location: string
}

interface LandingView extends RouteComponentProps<LandingViewMatchParams> {}

//const LandingView: React.FC<LandingView> = (props: LandingView) => {

const LandingView: React.FC<RouteComponentProps> = ({ location , history, match }: RouteComponentProps) => {
  const mainSeries = '';
  const { platesJson } = useContext(AppContext)
  const [hero, setHero] = useState<HeroProps | null>(
    (platesJson.assets && platesJson.assets.hero) || null
  )
  const [skyscrapers, setSkyscrapers] = useState<SkyscraperProps[] | null>(
    (platesJson.assets && platesJson.assets.skyscrapers) || null
  )
  const [stretchImage, setStretchImage] = useState<Stretch | null>(
    (platesJson.assets && platesJson.assets.stretch) || null
  )
  const [testimonial, setTestimonial] = useState<TestimonialProps | null>(
    (platesJson.assets &&
      platesJson.testimonials &&
      getRandomTestimonial(platesJson.testimonials)) ||
      null
  )
  //const [interstitial, setInterstitial] = useState<Interstitial | null>(null)
  const [plates, setPlates] = useState<Plate[] | null>(null)
  const popularPlates = getPopularPlates(plates)
  const uniqueSeries = getUniqueSeriesPlates(plates)

  const [footer, setFooter] = useState<FooterProp | null>(
    (platesJson.footer) || null
  )
  const [header, setHeader] = useState<HeaderProp | null>(
    (platesJson.header) || null
  )

  useEffect(() => {
    if (platesJson.series) {
      setPlates(generatePlates(platesJson.series))
    }
    if (platesJson.testimonials) {
      setTestimonial(getRandomTestimonial(platesJson.testimonials))
    }
    if (platesJson.assets) {
      setStretchImage(platesJson.assets.stretch || null)
      setHero(platesJson.assets.hero || null)
      setSkyscrapers(platesJson.assets.skyscrapers || null)
    }
    if (platesJson.footer) {
      console.log('main footer---',platesJson.footer.PrivacyPolicy_link)
      setFooter(platesJson.footer)
    }
    if (platesJson.header) {
      console.log('main footer---',platesJson.header.FAQs_link)
      setHeader(platesJson.header)
    }
  }, [platesJson])

  return (
    <>
      <Container full className={styles.headerBg}>
        {/* <Container> */}
        {header && <Header {...header} />}
          {/* </Container> */}
        {/* <Container> */}
        {hero && <Hero {...hero} />}
          {/* </Container> */}
      </Container>
      <Container>
        <main role='main'>
          <section className={styles.section}>
            <div className={styles.platesContainer}>
              <h2 className={styles.title}>Popular styles</h2>
              <PlateGrid plates={popularPlates} mainSeries={mainSeries} />
              <div className={styles.browseBtnCls}>
                <Button as='link' to={routes.filter.path} style='default' size='large'>
                  Browse Styles
                </Button>
              </div>
            </div>
            {skyscrapers && <Skyscraper {...skyscrapers[0]} />}
          </section>
          {platesJson.assets?.stretch?.show && (
            <>
              {stretchImage && <StretchImage {...stretchImage} />}
            </>
          )}
          {/* <section className={styles.section}>
            <div className={styles.platesContainer}>
              <h2 className={styles.title}>Available styles</h2>
              <PlateGrid plates={uniqueSeries} />
            </div>
            {skyscrapers && <Skyscraper {...skyscrapers[1]} />}
          </section> */}
          {/* {testimonial && <Testimonial {...testimonial} />} */}
        </main>
      </Container>
      <Interstitial location={location} history={history as any} match={match as any} mainSeries={mainSeries} />
      {/* {<Interstitial location={props.location.search} mainSeries={mainSeries} />} */}
      <Container full className={styles.footerBg}>
        {footer && <Footer {...footer} />}
      </Container>
    </>
  )
}

export default LandingView
