import React from 'react'
import classNames from 'classnames'
import Button from 'components/button'
import styles from './hero.module.scss'
import { routes } from 'App'
import AwesomeSlider from 'react-awesome-slider';
import "react-awesome-slider/dist/styles.css";
import "react-awesome-slider/dist/captioned.css";


interface HeroButton {
  text?: string
}

export interface Hero {
  title?: string
  subtitle?: string
  images?: string[]
  button?: HeroButton
}

const Hero: React.FC<Hero> = (props: Hero) => {
  //const isDesktop = useMedia(`(min-width: ${breakpoints.tablet})`)
  return (
    <header role='banner' className={styles.hero}>
      {<DesktopHero {...props} />}
      {/* {isDesktop && <DesktopHero {...props} />}
      {!isDesktop && <MobileHero {...props} />} */}
    </header>
  )
}

type Copy = Pick<Hero, 'title' | 'subtitle' | 'button'>

const Copy: React.FC<Copy> = ({ title, subtitle, button }: Copy) => (
  <div >
    {title && <h1 className={styles.title} style={{fontSize: '5.3rem',marginTop: 0 , marginBottom: '-3px'}} >{title}</h1>}
    {subtitle && <p className={styles.subTitle}>{subtitle}</p>}
    <Button as='link' to={routes.filter.path} size='large'>
      {button && button.text ? button.text : 'Browse styles'}
    </Button>
  </div>
)

const MobileHero: React.FC<Hero> = ({ title, subtitle, images, button }: Hero) => {
  let mobileImage1
  let mobileImage2
  let hasMobileImageSet = false

  if (images && images.length >= 2) {
    hasMobileImageSet = true
    // We always want to use the first image of a set.
    mobileImage1 = images[0]

    // If there are only two images in the set, use the 2nd one.
    if (images.length === 2) {
      mobileImage2 = images[1]
    } else {
      // If we have 3 or more, use the 3rd one (as initially planned).
      mobileImage2 = images[2]
    }
  }

  if (process.env.REACT_APP_ENV !== 'production' && !hasMobileImageSet) {
    console.warn('Missing images in order to display <Hero /> correctly.')
  }

  const logoColumnClasses = classNames(styles.column, {
    [styles.logoOnly]: !hasMobileImageSet,
  })

  return (
    <div className={styles.mobileContainer}>
      <div className={logoColumnClasses}>
        <div className={styles.logoContainer}>
          {/* <Logo /> */}
        </div>
        {hasMobileImageSet && (
          <div className={styles.image1}>
            <div className={styles.image} style={{ backgroundImage: `url(${process.env.REACT_APP_DOT_SERVER_URL}${mobileImage1})` }} />
          </div>
        )}
      </div>
      {hasMobileImageSet && (
        <div className={styles.column}>
          <div className={styles.image2}>
            <div className={styles.image} style={{ backgroundImage: `url(${process.env.REACT_APP_DOT_SERVER_URL}${mobileImage2})` }} />
          </div>
        </div>
      )}
      <Copy title={title} subtitle={subtitle} button={button} />
    </div>
  )
}

const DesktopHero: React.FC<Hero> = ({ title, subtitle, images, button }: Hero) => {
  let desktopImage1
  let desktopImage2
  let desktopImage3
  let desktopImage4
  let desktopImage5
  let hasDesktopImageSet = false

  if (images && images.length >= 5) {
    hasDesktopImageSet = true
    desktopImage1 = images[0]
    desktopImage2 = images[1]
    desktopImage3 = images[2]
    desktopImage4 = images[3]
    desktopImage5 = images[4]
  }

  if (process.env.REACT_APP_ENV !== 'production' && !hasDesktopImageSet) {
    console.warn('Missing images in order to display <Hero /> correctly.')
  }

  const containerClasses = classNames(styles.desktopContainer, {
    [styles.logoOnly]: !hasDesktopImageSet,
  })
  //{${process.env.REACT_APP_DOT_SERVER_URL}+''+{desktopImage1}};
  // 'https://dotwebdev01/PlatesWA/testimonial_footer.png';
  const contentStyle = {
    color: "black",
    fontSize: "20px"
  };
  return (

    // <section className={styles.mainBannerImage}>
    //   <Copy title={title} subtitle={subtitle} button={button} />
    //   <div className={styles.imageContainer}>
    //     <div className={styles.image} style={{ backgroundImage: desktopImage1 && `url(${process.env.REACT_APP_DOT_SERVER_URL}${desktopImage1})` }} />
    //   </div>
    // </section>

    <section className={styles.bgLight}>
      <div className={styles.firstSection}>
        <div className={styles.designPlate}>
          <Copy title={title} subtitle={subtitle} button={button} />
        </div>
        <div className={styles.mainAwsmSlider}>

          <AwesomeSlider bullets={false} cssModule={styles} >
            <img style={{ backgroundImage: desktopImage1 && `url(${process.env.REACT_APP_DOT_SERVER_URL}${desktopImage1})`,backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'top center'}} className={styles.imgFluidBackGrnd+ ' '+styles.w100}  alt="" />
            <img style={{ backgroundImage: desktopImage2 && `url(${process.env.REACT_APP_DOT_SERVER_URL}${desktopImage2})`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'top center'}} className={styles.imgFluidBackGrnd+ ' '+styles.w100} alt="" />
            <img style={{ backgroundImage: desktopImage3 && `url(${process.env.REACT_APP_DOT_SERVER_URL}${desktopImage3})`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'top center'}} className={styles.imgFluidBackGrnd+ ' '+styles.w100} alt="" />
            <img style={{ backgroundImage: desktopImage4 && `url(${process.env.REACT_APP_DOT_SERVER_URL}${desktopImage4})`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'top center'}} className={styles.imgFluidBackGrnd+ ' '+styles.w100} alt="" />
            <img style={{ backgroundImage: desktopImage5 && `url(${process.env.REACT_APP_DOT_SERVER_URL}${desktopImage5})`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'top center'}} className={styles.imgFluidBackGrnd+ ' '+styles.w100} alt="" />
          </AwesomeSlider>
        </div>
      </div>
    </section>

    // <div className={containerClasses}>
    //   <div className={styles.column}>
    //     {/* {hasDesktopImageSet && (
    //       <div className={styles.image1}>
    //         <div className={styles.image} style={{ backgroundImage: `url(${process.env.REACT_APP_DOT_SERVER_URL}${desktopImage1})` }} />
    //       </div>
    //     )} */}
    //     <Copy title={title} subtitle={subtitle} button={button} />
    //   </div>
    //   <div className={styles.column}>
    //     {/* <div className={styles.logoContainer}>
    //       <Logo />
    //     </div> */}
    //     {hasDesktopImageSet && (
    //       <div className={styles.image2}>
    //         <div className={styles.image} style={{ backgroundImage: `url(${process.env.REACT_APP_DOT_SERVER_URL}${desktopImage1})` }} />
    //       </div>
    //     )}
    //   </div>
    //   {/* {hasDesktopImageSet && (
    //     <div className={styles.column}>
    //       <div className={styles.image3}>
    //         <div className={styles.image} style={{ backgroundImage: `url(${process.env.REACT_APP_DOT_SERVER_URL}${desktopImage3})` }} />
    //       </div>
    //       <div className={styles.image4}>
    //         <div className={styles.image} style={{ backgroundImage: `url(${process.env.REACT_APP_DOT_SERVER_URL}${desktopImage4})` }} />
    //       </div>
    //     </div>
    //   )} */}
    // </div>
  )
}

export default Hero
