import React from 'react'
import Button from 'components/button'
import styles from './stretch-image.module.scss'
import { routes } from 'App'
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';

interface StretchImageButton {
  text?: string
  related_series?: string
}

export interface StretchImage {
  title?: string
  subtitle?: string
  image?: string
  image1?: string
  image2?: string
  image3?: string
  image4?: string
  button?: StretchImageButton
  show: boolean
}

const StretchImage: React.FC<StretchImage> = ({ title, subtitle, image, image1, image2, image3, image4, button, show }: StretchImage) => {
  if (!title || !subtitle || !image || !image1) {
    if (process.env.REACT_APP_ENV !== 'production') {
      console.warn('Missing properties in order to display <StretchImage />.')
    }
    return null
  }

  return (
    <section className={styles.stretchImage}>
      <AwesomeSlider bullets={false} cssModule={styles} >
        <img style={{ backgroundImage: image && `url(${process.env.REACT_APP_DOT_SERVER_URL}${image})`,backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'top center'}} className={styles.image}  alt="" />
        <img style={{ backgroundImage: image1 && `url(${process.env.REACT_APP_DOT_SERVER_URL}${image1})`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'top center'}} className={styles.image} alt="" />
        <img style={{ backgroundImage: image2 && `url(${process.env.REACT_APP_DOT_SERVER_URL}${image2})`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'top center'}} className={styles.image} alt="" />
        <img style={{ backgroundImage: image3 && `url(${process.env.REACT_APP_DOT_SERVER_URL}${image3})`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'top center'}} className={styles.image} alt="" />
        <img style={{ backgroundImage: image4 && `url(${process.env.REACT_APP_DOT_SERVER_URL}${image4})`, backgroundRepeat: 'no-repeat', backgroundSize:'cover', backgroundPosition:'top center'}} className={styles.image} alt="" />
      </AwesomeSlider>
      <div className={styles.copyContainer}>
        <div className={styles.copy}>
          <h2 className={styles.title}>{title}</h2>
          <p className={styles.subtitle}>{subtitle}</p>
        </div>
        {button && (
          <Button
            as='link'
            to={`${routes.filter.path}/${button.related_series || ''}`}
            size='large'
          >
            {button.text || 'Make it yours'}
          </Button>
        )}
      </div>
    </section>
  )
}

export default StretchImage
