import React, { useState, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { IPlatesJson } from './types'

declare global {
  interface Window {
    platesJson: IPlatesJson
  }
}

const defaultState = {
  error: '',
  setError: (error: string) => {},
  message: '',
  setMessage: (message: string) => {},
  loading: false,
  setLoading: (loading: boolean) => {},
  request: (endpoint: string): Promise<any> => new Promise<Promise<any>>(() => {}),
  platesJson: { series: null, testimonials: [], assets: {}, FAQs: {}, Privacy: {}, TermsOfUse:{}, BizPlatesTerms:{}, BizPlatesGuide:{}, ContactUs:{}, Thankyou:{}, header: {}, footer: {}, banner: {} } as IPlatesJson,
  setPlatesJson: (plates: IPlatesJson) => {},
  getPlatesJSON: (): Promise<any> => new Promise<Promise<any>>(() => {}),
}

export const AppContext = React.createContext(defaultState)

export const AppState = withRouter(props => {
  const [error, setError] = useState(defaultState.error)
  const [message, setMessage] = useState(defaultState.message)
  const [loading, setLoading] = useState(defaultState.loading)
  const [platesJson, setPlatesJson] = useState<IPlatesJson>(defaultState.platesJson)

  const request = (endpoint: string) => {
    setLoading(true)
    return new Promise((resolve, reject) => {
      fetch(endpoint, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(res => {
          res
            .json()
            .then((json: any) => {
              if (res.status !== 200) {
                const error = `${res.status}: ${json.message}`
                setError(error)
                reject(new Error(error))
              }
              setLoading(false)
              resolve(json as IPlatesJson)
            })
            .catch(e => {
              const error = `${res.status}: Server returned the incorrect data type.`
              setError(error)
              setLoading(false)
              reject(new Error(`${res.status}: ${e}`))
            })
        })
        .catch(e => {
          setError(e.message)
          setLoading(false)
          reject(new Error(e))
        })
    })
  }

  const getPlatesJSON = () => {
    console.log('ewrwrwerwe---',Window)
    if (process.env.REACT_APP_ENV === 'production') {
      return new Promise((resolve, reject) => {
        if (window.platesJson) {
          resolve(window.platesJson)
        } else {
          const error = 'Error retrieving plates data.'
          setError(error)
          reject(new Error(error))
        }
      })
    } else {
      const plates: IPlatesJson = require('./plateswa.json')
      return new Promise((resolve, _reject) => {
        resolve(plates)
      })
    }
  }

  const data = useMemo(
    () => ({
      error,
      setError,
      message,
      setMessage,
      request,
      loading,
      setLoading,
      platesJson,
      setPlatesJson,
      getPlatesJSON,
    }),
    [error, message, loading, platesJson]
  )

  return <AppContext.Provider value={data}>{props.children}</AppContext.Provider>
})
