import React, { useContext, useEffect, useState } from 'react'
import Logo from 'components/logo'
import styles from './header.module.scss'
import { routes } from 'App'
import { Link } from 'react-router-dom'

export interface FAQsLink {
  text: string
  url: string
}

export interface PlatesDesignLink {
  text: string
  url: string
}

export interface PlatesSeriesLink {
  text: string
  url: string
}

export interface PlatesWALogoLink {
  LogoImage: string
  LogoTargetUrl: string
}

export interface HeaderMain {
  FAQs_link?: FAQsLink
  PlatesDesign_link?: PlatesDesignLink
  PlatesSeries_link?: PlatesSeriesLink
  PlatesWALogo_link?: PlatesWALogoLink
}

const Header: React.FC<HeaderMain> = ({ FAQs_link, PlatesDesign_link, PlatesSeries_link, PlatesWALogo_link }: HeaderMain) => {
  console.log('header-----',FAQs_link?.text)
  const mainHeader =  'header';
  return (
    <header className={styles.header} role='banner'>
      <nav className={styles.navbar}>
        <div className={styles.navbarContainer+ ' '+styles.container}>
            <div className={styles.logo}><Logo mainData={mainHeader}/></div>
            <input type="checkbox" name="" id=""/>
            <div className={styles.hamburgerLines}>
                <span className={styles.line+ ' ' +styles.line1}></span>
                <span className={styles.line+ ' ' +styles.line2}></span>
                <span className={styles.line+ ' ' +styles.line3}></span>
            </div>
            <ul className={styles.menuItems}>
                <li>
                  <Link to={PlatesDesign_link?.url} rel="noopener noreferrer">
                    {PlatesDesign_link?.text}
                  </Link>
                </li>
                <li>
                  <a href={PlatesSeries_link?.url} target='_blank' rel="noopener noreferrer">{PlatesSeries_link?.text}</a>
                </li>
                <li>
                {/* <a href={FAQs_link?.url} target='_blank' rel="noopener noreferrer">
                    {FAQs_link?.text}
                </a> */}
                  <Link to={routes.faq.path} target='_blank' rel="noopener noreferrer">
                    {FAQs_link?.text}
                  </Link>
                </li>
            </ul>

        </div>
      </nav>
    </header>
  )
}

export default Header
