import React from 'react'
import styles from './skyscraper.module.scss'

export interface Skyscraper {
  images?: string[]
}

const Skyscraper: React.FC<Skyscraper> = ({ images }: Skyscraper) => {
  if (!images || !images.length) {
    if (process.env.REACT_APP_ENV !== 'production') {
      console.warn('Missing images in order to display <SkyScraper />.')
    }
    return null
  }

  return (
    // <div className={styles.skyscraper}>
    //   {images.map((imageSet, index) => (
    //     <div className={styles.imageContainer} key={`sk_${index}`}>
    //       <div
    //         className={styles.image}
    //         style={{ backgroundImage: imageSet.length ? `url(${process.env.REACT_APP_DOT_SERVER_URL}${imageSet})` : undefined }}
    //       />
    //     </div>
    //   ))}
    // </div>

    <div className={styles.skyscraper}>
      {images.map((imageSet, index) => (
        // <div key={`sk_${index}`}>
          <img
            src={`${process.env.REACT_APP_DOT_SERVER_URL}${imageSet}`} width='100%' style={{marginTop:'-5px'}} alt=""
          />
        // </div>
      ))}
    </div>
  )
}

export default Skyscraper
